import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/workspace/src/components/MainLayout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PhotoGallery = makeShortcode("PhotoGallery");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Country furnitures`}</h2>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`The latest news is represented by our country style furniture for houses and gardens produced with our special care for details. All our country furniture are made with cedar wood. Cedar allows us to produce single board tables (up to 4.40 metres long), to enrich your houses and gardens with a touch of typical Italian handicraft.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Click on the preview to view a larger image`}</p>
      </li>
    </ul>
    <PhotoGallery mdxType="PhotoGallery">{[['/images/agriturismo/tavolo-1_b.jpg', 'Tavolone 4,00 m., tinta naturale, doppia panca'], ['/images/agriturismo/tavolo-2_b.jpg', 'Tavolone 4,40 m., tinta noce, doppia panca'], ['/images/agriturismo/tavolo-3_b.jpg', 'Tavolone 4,00, tinta chiara, doppia panca'], ['/images/agriturismo/tavolo-4_b.jpg', 'Tavolone 3,50 m., non tinto, con panca e sedie'], ['/images/agriturismo/tavolo-5_b.jpg', 'Tavolo 2,20 m., stile moderno, tinta rossa, gambe in acciaio'], ['/images/agriturismo/tavolo-6_b.jpg', 'Tavolo stile artistico, 1,80 m., tavola unica di noce']]}</PhotoGallery>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      